<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t("users.heading.roles") }}</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("users.subheading.roles") }}</span>
      </div>
      <div class="card-toolbar">
        <router-link :to="{ name: 'users'}" class="btn btn-light-primary font-weight-bolder mr-2">
          <i class="ki ki-long-arrow-back icon-xs mr-2"></i>
          {{ $t("users.button.overview") }}
        </router-link>
        <b-button class="btn btn-success mr-2" @click.prevent="editRoles" ref="save_submit">{{ $t("users.button.save_roles") }}</b-button>
      </div>
    </div>
    <!--end::Header-->
     <div class="card-body row">
        <div class="col-6">
        <h5 class="mb-5">{{ $t('users.title.roles_assign') }}</h5>
        <b-list-group>
          <b-list-group-item
          v-for="(role, index) in roles"
          :key="index"
          >
            <b-form-checkbox :value="role" v-model="userRoles">{{ role.name }}</b-form-checkbox>
          </b-list-group-item>
        </b-list-group>
       </div>
       <div class="col-6 border-left">
         <h5 class="mb-5">{{ $t('users.title.individual_settings') }}</h5>
         <div class="d-flex align-items-center">
            <span class="bullet bullet-bar bg-primary align-self-stretch"></span>
            <div class="d-flex flex-column flex-grow-1 ml-5">
              <a href="#" class="text-dark-75">{{ $t('roles.title.global_permissions') }}</a>
            </div>
            <b-button class="btn btn-clean btn-link btn-hover-light-primary btn-xs btn-icon" variant="clean" v-b-modal.modal-global-rules  @click="setRulesType('core');">
              <i class="fa fa-lock text-primary"></i>
            </b-button>
          </div>
          <hr />
          <span class="text-muted mt-3 font-weight-bold font-size-sm">{{ $t('title.modules') }}:</span>
          <div class="d-flex align-items-center mt-3">
            <span class="bullet bullet-bar bg-default align-self-stretch"></span>
            <div class="d-flex flex-column flex-grow-1 ml-5">
              <a href="#" class="text-dark-75">
                <span class="fa fa-check text-success mr-2" v-b-tooltip.top="$t('roles.title.set')"></span>
                Evidence klientů
            </a>
            </div>
            <a href="#" class="btn btn-hover-light-primary btn-xs btn-icon" v-b-modal.modal-global-rules  @click="setRulesType('clients');">
              <i class="fa fa-lock text-primary"></i>
            </a>
          </div>
          <div class="d-flex align-items-center mt-3">
            <span class="bullet bullet-bar bg-default align-self-stretch"></span>
            <div class="d-flex flex-column flex-grow-1 ml-5">
              <a href="#" class="text-dark-75">Jídelní terminál</a>
            </div>
            <a href="#" class="btn btn-hover-light-primary btn-xs btn-icon" v-b-modal.modal-global-rules @click="setRulesType('kitchen');">
              <i class="fa fa-lock text-primary"></i>
            </a>
        </div>
          
       </div>
     </div>

      <GlobalRulesModal :rulesCategories=rulesCategories :haveRules=haveRules></GlobalRulesModal>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";
import GlobalRulesModal from "@/view/pages/core/roles/GlobalRulesModal";

export default {
  name: "user_roles",
  components: {
    GlobalRulesModal
  },
  data() {
    return {
      userRoles: [],
      roles: null,

      rulesType: null,
      rulesCategories: null,
      haveRules: [],
    };
  },
  beforeMount() {
    this.getRoles();
    this.getUserRoles();
  },
  metaInfo () { 
      return { title: this.$t("users.meta.subtitle.roles")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("users.meta.title_user"), subtitle: this.$t("users.meta.subtitle.roles")}]);
  },
  watch: {
    rulesType: function () {
      this.getRuleCategories();
    }
  },
  methods: {
    getUserRoles() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/users/" + this.$route.params.id + "/roles")
        .then((response) => {
          this.userRoles = response.data.data;
          this.loading = false;
        });
    }, 
    getRoles() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/roles")
        .then((response) => {
          this.roles = response.data.data;
          this.loading = false;
        });
    },
    editRoles(){
      // Loading spinner
      const submitButton = this.$refs["save_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.loading = true;

        ApiService.apiPost("/users/" + this.$route.params.id + "/roles", 
        { 
          'roles': this.userRoles
        })
        .then(() => {
            this.loading = false;
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
        }).catch(error => {
            checkErrors(error);
        });
    },
    setRulesType(type){
    	this.rulesType = type;
    },
    getRuleCategories() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/rules/" + this.rulesType)
        .then((response) => {
          this.rulesCategories = response.data.data;
          this.loading = false;
        });
    },
    updateHaveRulesList(value){
      this.haveRules = value;
    },
    editHaveRules(){      
      this.loading = true;
      ApiService.setHeader();      
      ApiService.apiPost("users/" + this.$route.params.id + "/rules",{
        'rules':this.haveRules
      })
        .then(() => {
            this.loading = false;
            this.$bvModal.hide('modal-global-rules');
            this.getRoles();
        }).catch(error => {
            checkErrors(error);
      });
    }
  }
};
</script>
